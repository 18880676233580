// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
@use '~@cloudscape-design/design-tokens' as cs;
@use './base';

.input-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 0;
  margin-bottom: -1rem;

  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 428px;
}

.select-filter {
  max-width: 330px;
  flex-grow: 2;
  width: auto;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
  color: cs.$color-text-form-default;
}

@media (max-width: 1152px) {
  .input-container {
    margin-right: -1rem;
  }

  .select-filter {
    max-width: none;
  }

  .input-filter {
    width: 100%;
    max-width: none;
  }
}
